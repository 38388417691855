.mtksa-chart {

    &.mtksa-chart-scorecard,
    &.mtksa-chart-kpi,
    &.mtksa-chart-user {
        height: 178px;
        border-radius: $borderradius;
        box-shadow: $boxshadow;
        border: $boxborder;
        background-color: $white;
        padding: 24px;
        display: inline-flex;
        flex-direction: row;

        .scorewrapper {
            position: relative;

            .score {
                font-size: 36px;
                font-weight: 600;
                letter-spacing: -2px;
                line-height: 44px;
                margin-top: 24px;
            }

            .delta {
                position: absolute;
                bottom: 0;
                border: 0.5px solid $neutral-500;
                background-color: $neutral-050;
                font-size: 12px;
                font-weight: 400;

                &.negative {
                    border-color: $danger-500;
                    background-color: $danger-050;
                    color: $danger-500;
                }

                &.positive {
                    border-color: $success-500;
                    background-color: $success-050;
                    color: $success-500;
                }
            }
        }

        .chartwrapper {}
    }

    &.mtksa-chart-kpi,
    &.mtksa-chart-user {
        position: relative;
        height: 160px;
        gap:10px;
        padding-left: 120px;
        .iconwrapper {
            position: absolute;
            top:0;
            left:0;
            border-radius:  $borderradius 0 0  $borderradius;
            width: 100px;
            height: 100%;
            background-color: $neutral-100;
            border: 1px solid $white;
            border-right-width: 0.5px;
            border-right-color: $neutral-400;
            svg {
                font-size: 40px;
                color: $secondary-600;
                margin: 70% auto auto;
                display: block;
            }
        }
        .scorewrapper {
            .title {
                color:$primary-300;
            }
            .score {
                color:$primary-700;
                font-size: 60px;
            }
        }

        .userwrapper {
            .title {
                color:$primary-300;
            }
            .name {
                color:$primary-700;
                font-size: 26px;
                line-height: 40px;
                margin-top: 20px;
            }
            .email {
                color:$primary-300;
            }
        }
    }
}

.mtksa-chart-wrapper {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;

    .mtksa-chart {
        flex-grow: 1;
        flex-basis: 0;
        &.list-system {
            max-width: 32.3%;
        }
    }
}