.list-element-cell {
    &.cell-email {
        a {
            vertical-align: middle;
            font-size: 14px;
        }
    }

    &.cell-drawer {
        button {
            border: unset;
            min-height: unset;
            height: 30px;
            padding: 0 10px;
        }
    }

    &.cell-people,
    &.cell-legalentities,
    &.cell-bis {
        ul {
            display: inline-flex;
            flex-direction: row;
            gap: 3px;
            li {
                &.cell-list-item-count {
                    background-color: $neutral-100;
                    border:0.5px solid $neutral-300;
                    width: auto;
                    min-width: 24px;
                    span {
                        color: $neutral-500;
                        letter-spacing: -1px;
                    }
                }
            }
        }
    }

    &.cell-people,
    &.cell-bis {
        ul {
            li {
                display: inline-block;
                background-color: $success-100;
                border:0.5px solid $success-300;
                border-radius: 12px;
                width: 24px;
                height: 24px;
                line-height: 24px;
                min-height: unset;
                text-align: center;
                padding:0 3px;
                box-shadow: $boxshadow;
                span {
                    color: $success-700;
                    font-size: 10px;
                    text-transform: uppercase;
                    display: block;
                }

            }
        }
    }

    &.cell-legalentities {
        ul {
            li {
                display: inline-block;
                background-color: $neutral-100;
                border:0.5px solid $neutral-300;
                border-radius: 12px;
                width: auto;
                height: 24px;
                line-height: 24px;
                min-height: unset;
                text-align: center;
                padding:0 8px;
                box-shadow: $boxshadow;
                span {
                    color: $neutral-700;
                    font-size: 10px;
                    text-transform: uppercase;
                    display: block;
                }

            }
        }
    }
}