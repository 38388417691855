footer {
    padding: 0 20px;
    background-color: $primary-600;
    line-height: $footer_height;

    display: flex;
    gap:30px;

    .copyright,
    .privacy,
    .cookie {
        p, a {
            line-height: $footer_height;
            font-size: 10px;
            font-weight: 100;
            color: $primary-400;
        }
    
        a {
            color: $primary-300;
            display: block;
            text-decoration: none;
            &:hover {
                color: $primary-100;
                text-decoration: underline;
            }
        }
    }
}