$fieldheight: 44px;

.form-element {
    font-family: $font-primary;

    .MuiInput-startDecorator,
    .MuiSelect-startDecorator,
    .MuiTextarea-startDecorator {
        width: 15px;
        height: $fieldheight;
        padding-right: 10px;
        border-right: 0.5px solid $neutral-200;

        svg {
            width: 13px;
        }

        &:empty {
            display: none;
        }
    }

    .MuiTextarea-startDecorator {
        height: auto;
        margin-bottom: 0;
        padding-top: 14px;
        padding-left: 7px;
        padding-right: 10px;
    }

    .MuiTextarea-endDecorator {
        margin: 0;
        width: 10px;
        text-align: center;
        padding: 14px 18px;
    }

    .form-element-control {
        .form-element-label {
            font-family: $font-primary;
            font-weight: 500;
            color: $neutral-700;
            font-size: 14px;
            text-transform: capitalize;
        }

        .form-element-field {
            border: 0.5px solid $neutral-200;
            padding-top: 0;
            padding-bottom: 0;
            margin: 0;
            border-radius: $borderradius;
            box-shadow: $boxshadow;
            min-height: $fieldheight;

            input,
            button {
                border-radius: 8px;
                height: $fieldheight;
                font-size: 16px;
                font-weight: 400;

                &[type="file"] {
                    padding: 10px;
                    height: 24px;
                    font-size: 14px;
                }
            }
        }

        .form-element-hint {
            font-size: 14px;
            font-weight: 400;
            color: $neutral-400;
        }

        .form-element-dropbox-area {

            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            color: #bdbdbd;
            outline: none;
            transition: border .24s ease-in-out;

            border-radius: $borderradius;
            box-shadow: $boxshadow;
            min-height: $fieldheight * 2;
            border: 1px dashed $neutral-200;
            cursor: pointer;

            .filesPreview {
                display: flex;
                gap: 10px;
                list-style-type: none;

                .filePreview {
                    border: 0.5px solid $neutral-200;
                    border-radius: 5px;
                    padding: 20px;
                }
            }

            &:hover {
                border-color: $success-200;
            }
        }

        &.error,
        &.form-element-error {

            .MuiInput-startDecorator,
            .MuiSelect-startDecorator,
            .MuiTextarea-startDecorator {
                border-right-color: $danger-400;
            }

            .form-element-label {
                color: $danger-400;
            }

            .form-element-field {
                border-color: $danger-400;
                background-color: $danger-050;

                svg {
                    path {
                        fill: $danger-400;
                    }
                }

                input,
                textarea {
                    color: $danger-400;
                }
            }

            .form-element-hint {
                color: $danger-400;
            }
        }

        &.required {
            .form-element-label {
                &::after {
                    content: "*";
                    padding-left: 5px;
                    color: $danger-500;
                }
            }
        }

        &.disabled,
        &.readonly {

            .MuiInput-startDecorator,
            .MuiSelect-startDecorator,
            .MuiTextarea-startDecorator {
                svg {
                    path {
                        fill: $neutral-400;
                    }
                }
            }

            .form-element-label {
                color: $neutral-400;
            }

            .form-element-field {
                input {
                    color: $neutral-500;
                }
            }

            .form-element-field {
                background-color: $neutral-100;
            }

            .form-element-hint {
                color: $neutral-300;
            }

            .form-element-relationship-options {
                ul {
                    >li {
                        color: $neutral-500;
                        background-color: $neutral-100;
                    }
                }
            }

            .form-element-field-select {
                button {
                    color: $neutral-500;
                }
            }

            &.error,
            &.form-element-error {
                .form-element-label {
                    color: $danger-300;
                }

                .form-element-field {
                    background-color: $danger-050;
                }

                .form-element-hint {
                    color: $danger-300;
                }

                .form-element-field {
                    input {
                        color: $danger-300;
                    }
                }
            }
        }
    }

    &.hidden {
        display: none;
    }
    
    &.radio,
    &.checkbox {
        .form-element-field {
            padding: 12px;

            input {
                height: 24px;
                aspect-ratio: 1 / 1;
            }
        }
    }

    &.radio {
        .MuiRadio-root {
            margin: 0;
            padding: 8px 0;
        }
    }

    &.checkbox {
        .form-element-control {
            .form-element-label {
                margin: 0 0 0.375rem 0;
            }

            .form-element-field {
                display: flex;
                flex-direction: column;
                gap: 12px;

                ul,
                li {
                    padding: 0;
                }
            }
        }
    }

    &.textarea {
        .form-element-field {
            display: flex;
            flex-direction: row;
            gap: 3px;

            textarea {
                margin: 0;
                padding: 10px 0;
            }
        }
    }

    &.switch {
        .form-element-control {
            flex-direction: column;

            .form-element-label {
                margin: 0 0 0.375rem 0;
            }

            .form-element-field {
                width: 100%;

                .MuiSwitch-startDecorator {
                    padding: 0 10px;

                    &:empty {
                        padding: 0 4px;
                    }
                }

                .MuiSwitch-endDecorator {
                    svg {
                        position: absolute;
                        right: 12px;
                        top: 14px;
                    }
                }
            }
        }
    }

    &.relationship {
        .form-element-relationship-options {
            ul {
                padding-top: 0;

                >li {
                    border-radius: 8px;
                    padding: 0 10px;
                    height: $fieldheight;
                    border: 0.5px solid $neutral-200;
                    border-radius: $borderradius;
                    box-shadow: $boxshadow;
                    min-height: $fieldheight;
                    background-color: $neutral-050;

                    button {
                        svg {
                            font-size: 16px;
                            color: $neutral-400;
                        }
                    }
                }
            }

            .relationship-activator {
                width: 100%;
                height: 40px;
                line-height: 38px;
                display: block;
                padding: 0 10px;
                text-align: left;
                position: relative;
                box-shadow: 0 0 0 3px $secondary-200;

                &:after {
                    content: "+";
                    font-size: 16px;
                    color: $secondary-500;
                    position: absolute;
                    right: 20px;
                    top: -2px;
                }
            }
        }
    }
}