// LAYOUT
$app_top_border_height: 8px;
$header_height: 70px;
$header_padding-top: 10px;
$module_menu_height:120px;
$footer_height:20px;
$body_height:calc(100vh - $app_top_border_height - $header_height - $footer_height);
$body_padding_right:40px;

// TYPOGRAPHY
$font-primary:"Inter", sans-serif;
$font-secondary:Arial, sans-serif;

:root {
    --white:#FFF;
    --black:#000;

    --primary-050: #F8F9FC;
    --primary-100: #EAECF5;
    --primary-200: #D5D9EB;
    --primary-300: #AFBBD9;
    --primary-400: #7186BC;
    --primary-500: #4E67A6;
    --primary-500-a30: #4E67A630;
    --primary-600: #3E4D84;
    --primary-700: #363F72;
    --primary-800: #293056;
    --primary-900: #242645;

    --secondary-050: #F0F9FF;
    --secondary-100: #E0F2FE;
    --secondary-200: #B9E6FE;
    --secondary-300: #7CD4FD;
    --secondary-400: #36BFFA;
    --secondary-500: #0BA5EC;
    --secondary-600: #0086C9;
    --secondary-700: #026AA2;
    --secondary-800: #065986;
    --secondary-900: #0B4A6F;

    --neutral-050: #FBFCFE;
    --neutral-100: #F0F4F8;
    --neutral-200: #DDE7EE;
    --neutral-300: #CDD7E1;
    --neutral-400: #9FA6AD;
    --neutral-500: #636B74;
    --neutral-600: #555E68;
    --neutral-700: #32383E;
    --neutral-800: #171A1C;
    --neutral-900: #0B0D0E;

    --success-050: #F6FEF6;
    --success-100: #E3FBE3;
    --success-200: #C7F7C7;
    --success-300: #A1E8A1;
    --success-400: #51BC51;
    --success-500: #1F7A1F;
    --success-600: #136C13;
    --success-700: #0A470A;
    --success-800: #042F04;
    --success-900: #021D02;

    --warning-050: #FEFAF6;
    --warning-100: #FDF0E1;
    --warning-200: #FCE1C2;
    --warning-300: #F3C896;
    --warning-400: #EA9A3E;
    --warning-500: #9A5B13;
    --warning-600: #72430D;
    --warning-700: #492B08;
    --warning-800: #2E1B05;
    --warning-900: #1D1002;

    --danger-050: #FEF6F6;
    --danger-100: #FCE4E4;
    --danger-200: #F7C5C5;
    --danger-300: #F09898;
    --danger-400: #E47474;
    --danger-500: #C41C1C;
    --danger-600: #A51818;
    --danger-700: #7D1212;
    --danger-800: #430A0A;
    --danger-900: #240505;
}

$white: var(--white);
$black: var(--black);

$primary-050: var(--primary-050);
$primary-100: var(--primary-100);
$primary-200: var(--primary-200);
$primary-300: var(--primary-300);
$primary-400: var(--primary-400);
$primary-500: var(--primary-500);
$primary-500-a30: var(--primary-500-a30);
$primary-600: var(--primary-600);
$primary-700: var(--primary-700);
$primary-800: var(--primary-800);
$primary-900: var(--primary-900);

$secondary-050: var(--secondary-050);
$secondary-100: var(--secondary-100);
$secondary-200: var(--secondary-200);
$secondary-300: var(--secondary-300);
$secondary-400: var(--secondary-400);
$secondary-500: var(--secondary-500);
$secondary-600: var(--secondary-600);
$secondary-700: var(--secondary-700);
$secondary-800: var(--secondary-800);
$secondary-900: var(--secondary-900);

$neutral-050: var(--neutral-050);
$neutral-100: var(--neutral-100);
$neutral-200: var(--neutral-200);
$neutral-300: var(--neutral-300);
$neutral-400: var(--neutral-400);
$neutral-500: var(--neutral-500);
$neutral-600: var(--neutral-600);
$neutral-700: var(--neutral-700);
$neutral-800: var(--neutral-800);
$neutral-900: var(--neutral-900);

$success-050: var(--success-050);
$success-100: var(--success-100);
$success-200: var(--success-200);
$success-300: var(--success-300);
$success-400: var(--success-400);
$success-500: var(--success-500);
$success-600: var(--success-600);
$success-700: var(--success-700);
$success-800: var(--success-800);
$success-900: var(--success-900);

$warning-050: var(--warning-050);
$warning-100: var(--warning-100);
$warning-200: var(--warning-200);
$warning-300: var(--warning-300);
$warning-400: var(--warning-400);
$warning-500: var(--warning-500);
$warning-600: var(--warning-600);
$warning-700: var(--warning-700);
$warning-800: var(--warning-800);
$warning-900: var(--warning-900);

$danger-050: var(--danger-050);
$danger-100: var(--danger-100);
$danger-200: var(--danger-200);
$danger-300: var(--danger-300);
$danger-400: var(--danger-400);
$danger-500: var(--danger-500);
$danger-600: var(--danger-600);
$danger-700: var(--danger-700);
$danger-800: var(--danger-800);
$danger-900: var(--danger-900);


// BOX
$borderradius: 8px;
$boxshadow:  var(--joy-shadowRing, 0 0 #000),0px 1px 2px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08));
$boxborder: 1px solid $neutral-100;
