header {
    position: relative;
    background-color: $neutral-050;
    display: flex;
    height: $header_height;
    padding:$header_padding-top 0 0 $body_padding_right;
    border-radius: 25px 0 0 0;

    .filler {
        flex-grow: 1;
    }

    // &:before {
    //     content:"";
    //     position: absolute;
    //     top:0;
    //     left:0;
    //     width: 50px;
    //     aspect-ratio: 1 / 1;
    //     background-color: $primary-600;
    //     clip-path: path("M50,0H0v50C0,22.4,22.4,0,50,0z");
    // }
}