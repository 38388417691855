.list-system {
    .list-wrapper {
        border-radius: 12px;
        background-color: $white;
        border: 0.5px solid $neutral-300;
        box-shadow: $boxshadow;

        .list-toolbar {
            border-bottom: 0.5px solid $neutral-300;
            min-height: 44px;
            padding: 20px 24px; 
            justify-content: space-between; 

            .list-toolbar-secondary {
                .MuiFormControl-root {
                    padding: 0 10px;
                    border: 1px solid $neutral-300;
                    border-radius: 8px;

                    .MuiInputBase-root {
                        &::before {
                            border: unset;
                        }

                        input {
                            height: 44px;
                            padding: 0;
                        }
                    }
                }
            }

            .list-toolbar-primary {
                display: flex;
                button {
                    margin-left: 5px;
                }
            }
        }

        .MuiDataGrid-main {
            border-radius: 0 !important;

            .MuiDataGrid-virtualScroller {
                border-radius: 0 !important;

                .MuiDataGrid-columnHeaders {
                    border-radius: 0 !important;
                    background: $neutral-100;
                    --DataGrid-headerHeight: 44px;

                    *,
                    >div {
                        background-color: transparent;
                    }

                    .MuiDataGrid-columnHeader {
                        height: 44px !important;

                        &:focus,
                        &:focus-within {
                            outline: unset;
                        }

                        .MuiDataGrid-columnHeaderTitleContainer {
                            .MuiDataGrid-columnHeaderTitle {
                                color: $neutral-600;
                                font-size: 12px;
                                font-weight: 400;
                                text-transform: capitalize;
                            }
                        }

                        &.MuiDataGrid-columnHeader--sorted {
                            .MuiDataGrid-iconButtonContainer {
                                .MuiSvgIcon-root {
                                    fill: $secondary-500;
                                }
                            }
                        }
                    }
                }

                .MuiDataGrid-row {
                    .MuiDataGrid-cell {
                        font-family: 'Inter';

                        &:focus,
                        &:focus-within {
                            outline: 0.5px solid $secondary-500;
                        }
                    }
                }
            }
        }

        .MuiSvgIcon-root {
            font-size: 1.2rem;
            fill: $neutral-300;
        }

        .MuiCheckbox-indeterminate,
        .Mui-checked {
            .MuiSvgIcon-root {
                fill: $secondary-500;
            }
        }
    }
}

.MuiDataGrid-menu {
    .MuiListItemIcon-root {
        min-width: 30px;
        color: $neutral-300;
    }

    .MuiTypography-root {
        font-family: 'Inter';
        font-size: 12px;
        color: $neutral-600;
    }
}