aside {
    background-color: $primary-600;
    position: relative;

    .aside-minimizer {
        position: absolute;
        width: 30px;
        height: 30px;
        right: -15px;
        top: 50%;
        transform: translateY(-50%);

        button {
            font-size: 10px;
            width: 30px;
            height: 30px;
            min-width: unset;
            min-height: unset;
            border-radius: 50%;
            background: $primary-500;
            border: 2px solid $neutral-100;
            color: $white;

            &:hover {
                background-color: $primary-900;
            }
        }

        &.hidden {
            display: none;
        }
    }

    &.closed {
        .appname {
            .name {
                display: none;
            }
        }

        .app-menu {
            .app-menu-portion {

                >li {
                    padding: 3px;

                    .MuiLink-startDecorator {
                        width: 100%;
                    }

                    .name {
                        display: none;
                    }
                }
            }
        }

        .user-menu {
            grid-template-columns: 32px;
            height: 32px;

            .user-icon {
                line-height: 32px;
                font-size: 12px;
                width: 32px;
                height: 32px;
                background-color: $primary-100;
                color:$primary-600;
                border:0;
            }

            .user-name {
                display: none;
            }

            .user-email {
                display: none;
            }

            .user-logout {
                display: none;
            }
        }
        .debugger {
            display: none;
        }
    }
}