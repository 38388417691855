@mixin centerelementpadding($hwrapper, $helement) {
	padding: calc(($hwrapper - $helement) / 2);
}
@mixin centerelement($hwrapper, $helement) {
	margin-top: calc(($hwrapper - $helement) / 2);
}
@mixin maxlines($numlines) {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $numlines;
	-webkit-box-orient: vertical;
}
@mixin nomaxlines() {
	overflow: visible;
	text-overflow:unset;
	display:inline;
	-webkit-line-clamp:unset;
}
@mixin fullwidth() {
	width: 100vw;
	left: 50%;
	position: relative;
	transform: translateX(-50%);
	clear: both;
	max-width: none;
}
@mixin skeleton() {
	height: 24px;
	border-radius: 3px;
	background-image: linear-gradient(-45deg, #dee2e6, #f7f7f7, #dee2e6) !important;
	background-size: 400% 400% !important;
	animation: background 3s ease infinite !important;
	color: transparent;
	* {
		color: transparent;
	}
}
@mixin tileshadow() {
	box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
}