.importer {
    .debug {
        display: none;
    }

    .importer-form {
        .import-stage {
            display: flex;
            gap: 10px;

            &.import-stage-hidden {
                display: none;
            }

            .form-element {
                flex: 1 1 0px;
                label {
                    text-transform: none;
                }
                &.import-actions {
                    margin-top: 30px;
                }
            }
        }
    }

    .importer-data-wrapper {
        margin-top: 40px;
        overflow-x: scroll;

        table {
            width: auto;
            th,
            td {
                min-width: 100px;
            }
        }
    }
}