@import '../variables';
@import '../animations';
@import '../functions';
@import '../typography';

* {
    box-sizing: border-box;
}

body {
    font-family: $font-primary;
    margin: 0;
    padding: 0;

    .app {
        display: grid;
        grid-template-columns: repeat(24, 1fr);
        grid-template-rows: $app_top_border_height $header_height $body_height $footer_height;
        background-color: $primary-600;

        .superheader {
            grid-row: 1 / 1;
            grid-column: 1 / 25;
        }

        header {
            grid-row: 2 / 2;
            grid-column: 5 / 25;
        }

        aside {
            grid-row: 2 / 4;
            grid-column: 1 / 5;
        }

        main {
            grid-row: 3 / 3;
            grid-column: 5 / 25;
        }

        footer {
            grid-row: 4 / 4;
            grid-column: 1 / 25;
        }

        &.closed {
            aside {
                grid-column: 1 / 1;
            }

            header {
                grid-column: 2 / 25;
            }

            main {
                grid-column: 2 / 25;
            }
        }

        @import './Layouts/superheader';
        @import './Layouts/header';
        @import './Layouts/aside';
        @import './Layouts/main';
        @import './Layouts/footer';
    }

    .app-login {
        height: 100vh;
        background: linear-gradient(-45deg, $primary-600, $primary-700);
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;

        main {
            width: 40vw;
            height: auto;
            background-color: $white;
            border-radius: 50px 8px 50px 8px;
            border:0.5px solid $primary-100;
            border-top: 20px solid $primary-100;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            padding: 30px 30px 60px 30px;

            .app-login-panel {
                text-align: center;
                h4 {
                    margin-top: 45px;
                    text-align: center;
                    color: $primary-300;
                    display: block;
                }

                h1 {
                    text-align: center;
                    color: $primary-600;
                    display: block;
                }

                p {
                    margin-top: 15px;
                }

                button {
                    margin-top: 30px;
                    padding: 0 15px;
                    line-height: 50px;
                    background-color: $primary-600;

                    &:hover {
                        background-color: $primary-500;
                    }
                }
            }

        }
    }
}