.drawer-wrapper {
    --Drawer-current-horizontalSize: 60vw;
    --Drawer-ancestor-horizontalSize: 100vw;

    .MuiDrawer-content {
        width: var(--Drawer-current-horizontalSize);
        background-color: $neutral-100;

        .drawer-section-header {
            background-color: $white;
            margin: 0px;
            padding: 20px;
            border-bottom: 0.5px solid $neutral-300;
            display: flex;
            .mtska-text-title-md {
                margin-left: 5px;
            }
            .MuiModalClose-root {
                position: static;
                top: 0;
                left: 0;
            }
            .button-main {
                margin-left: auto;
            }
        }

        .drawer-section-content {
            padding: 40px;
        }
    }

    .MuiDrawer-backdrop {
        backdrop-filter: blur(1px);
        background-color: $primary-500-a30;
    }

    &.drawer-wrapper-fullwidth-true {
        .MuiDrawer-content {
            width: var(--Drawer-ancestor-horizontalSize);
        }
    }
}