button {
    &.input-button {
        font-weight: 500;

        &.small {
            font-size: 10px;
            line-height: 16px;
        }

        &.medium {
            font-size: 14px;
            line-height: 18px;
        }

        &.regular {
            font-size: 16px;
            line-height: 24px;
        }

        &.big {
            font-size: 22px;
            line-height: 34px;
        }

        .MuiButton-startDecorator,
        .MuiButton-endDecorator {
            &:empty {
                display: none;
            }
        }

        &.iconic {
            padding: 5px;
            border-radius: 18px;
            width: 36px;
            height: 36px;

            .MuiButton-startDecorator {
                margin: 0;
            }
        }

        &.drawer-main-action {
            position: fixed;
            top: 18px;
            right: 20px;
        }
    }
}