main {
    background-color: $neutral-050;
    padding:40px 20px 40px $body_padding_right;
    overflow-y: scroll;
    border-radius: 0 0 0 10px;
    .module-body {
        .module-body-section-title {
            color:$primary-500;
            margin-bottom: 10px;
        }
        .module-body-section-subtitle {
            color:$primary-300;
            margin-bottom: 40px;
        }
    }
}