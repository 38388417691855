.app-menu {
    padding:50px 13px;
    .app-menu-portion {
        gap:10px;
        > li {
            padding:3px 3px 3px 10px;
            border-radius: 4px;
            > a {
                padding:0;
                color: $neutral-100;
                font-size: 16px;
                font-weight: 500;
                flex:initial;
                width: 100%;
                margin: 0;
                &:hover {
                    background-color: transparent;
                }
                > span {
                    min-inline-size: 0;
                    margin-inline-end: 0;
                    width: 30px;
                    > svg {
                        margin: 0 auto;
                        height: 1.3em;
                    }
                    &.name {
                        margin-left: 10px;
                        font-size: 14px;
                        font-weight: 300;
                        width: auto;
                    }
                }
            }

            &:hover {
                background-color: $primary-700;
            }

            &.app-menu-item-current {
                background-color: $primary-400;
                > a {
                    color: $primary-200;
                }
            }
        }
    }
}