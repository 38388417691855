.user-menu {
    display: grid;
    grid-template-columns: 40px auto 20px;
    grid-template-rows: 25px 25px;
    padding: 0 20px;
    width: 100%;
    position: absolute;
    bottom: 20px;
    .user-icon {
        grid-column: 1 / 1;
        grid-row: 1 / span 2;
        border-radius: 25px;
        background-color:$primary-200;
        color:$primary-600;
        font-size: 14px;
        line-height: 40px;
        height: 40px;
        margin-top: 5px;
        letter-spacing: -1px;
        text-align: center;
    }
    .user-name {
        grid-column: 2 / 2;
        grid-row: 1 / 1;
        font-size: 14px;
        line-height: 28px;
        color: $primary-200;
        padding:0 10px;
        font-weight: 700;
    }
    .user-email {
        grid-column: 2 / 2;
        grid-row: 2 / 2;
        font-size: 14px;
        line-height: 22px;
        color: $primary-200;
        padding:0 10px;
        font-weight: 300;
    }
    .user-logout {
        grid-column: 3 / 3;
        grid-row: 1 / span 2;
        font-size: 0;
        svg {
            path {
                font-size: 0px;
                fill: $primary-700;
            }
        }
        &:hover {
            background-color: transparent !important;
            svg {
                path {
                    fill: $primary-100;
                }
            }
        }
    }
}