.modulename {
    h2 {
        font-family: $font-primary;
        margin: 0;
        font-size: 30px;
        letter-spacing: 0px;
        font-weight: 700;
        line-height: $header_height - $header_padding-top;
        color: $neutral-800;
    }
}