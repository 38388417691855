.module-menu {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $primary-300;
    ul.module-menu-portion {
        display: flex;
        flex-direction: row;
        li.module-menu-item {
            gap:5px;
            a {
                &.icon {
                    .MuiLink-startDecorator {
                        margin: 0;
                    }
                    color: $primary-200;
                    &.preferred {
                        color:$secondary-500;
                    }
                }
                &.label {
                    font-size: 14px;
                    color: $primary-300
                }
            }
            &.module-menu-item-selected {
                position: relative;
                a {
                    &.label {
                        color: $primary-600
                    }
                }
                &:after {
                    content:"";
                    position: absolute;
                    height: 3px;
                    width: 100%;
                    background-color: $primary-600;
                    left: 0;
                    bottom: -7px;
                }
            }
        }
    }
}