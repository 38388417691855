div.form-wrapper {
    border:unset;
    background-color: transparent;
    .form-section-header-wrapper {
        .form-section-header-content {
            .form-section-header-action {
                position: absolute;
                right: 0;
            }
        }
    }
    .form-section-fields-wrapper {
        .form-section-fields-content {
            display: grid;
            gap:30px 15px;
            grid-template-columns: repeat(2, 1fr);

            .fullwidth {
                grid-column: 1 / 3;
            }
        }
        .MuiCardActionArea-focusHighlight {
            background-color: transparent;
            &:hover {
                background-color: pink;
            }
        }
    }
}