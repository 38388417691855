.mtska-text {
    .MuiTypography-startDecorator {
        &:empty {
            display: none;
        }
    }
    &.mtska-text-title-md {
        font-size: 18px;
    }
}
.mtska-link {
    .MuiLink-startDecorator {
        &:empty {
            display: none;
        }
    }
}